.assignments-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.assignment {
  width: 100%;
  margin-top: 68px;
  padding: 40px;
}

.assignment .title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  padding-bottom: 15px;
  letter-spacing: -0.01em;
  color: #353545;
}
.assignment .title:not(:first-child) {
  padding-top: 15px;
}

.error {
  border: 2px solid #ff6565;
}

.error-message {
  color: #ff6565;
  height: 1em;
  position: absolute;
  font-size: 16px;
}

.form-input {
  min-height: 80px;
}

.download-contract {
  width: 150px;
  color: #007bff;
  background-color: transparent;
  text-decoration: none;
}
.download-contract:hover {
  text-decoration: underline #007bff;
}

.download-contract:hover .view {
  cursor: pointer;
}

.assignments-header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  color: #353545;
}

.assignments-header-search {
  display: flex;
}

.assignments-header-search-text {
  margin-right: 10px;
}

.table-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}
