.header {
  position: absolute;
  width: 100%;
  height: 68px;
  left: 0;
  top: 0;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(204, 209, 221, 0.5), 0 1px 1px rgba(204, 209, 221, 0.4), 0 -1px 0 #ccd1dd;
}

.header .logo {
  height: 28px;
}
